// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconUserCheck, IconSettings, IconMessages, IconLayoutKanban, IconMail, IconCalendar, IconNfc, IconDeviceAnalytics, IconBrandBooking } from '@tabler/icons';

// constant
const icons = {
    IconUserCheck,
    IconSettings,
    IconMessages,
    IconLayoutKanban,
    IconMail,
    IconCalendar,
    IconNfc,
    IconDeviceAnalytics, 
    IconBrandBooking
};

// ==============================|| APPLICATION MENU ITEMS ||============================== //

const application = {
    id: 'application',
    title: <FormattedMessage id="application" />,
    type: 'group',
    children: [
        {
            id: 'users',
            title: <FormattedMessage id="users" />,
            type: 'item',
            icon: icons.IconSettings,
            url: '/user/account-profile/profile3'
            // children: [
            //     {
            //         id: 'posts',
            //         title: <FormattedMessage id="social-profile" />,
            //         type: 'item',
            //         url: '/user/social-profile/posts'
            //     },
            //     {
            //         id: 'account-profile',
            //         title: <FormattedMessage id="account-profile" />,
            //         type: 'collapse',
            //         children: [
            //             {
            //                 id: 'profile1',
            //                 title: (
            //                     <>
            //                         <FormattedMessage id="profile" /> 01
            //                     </>
            //                 ),
            //                 type: 'item',
            //                 url: '/user/account-profile/profile1'
            //             },
            //             {
            //                 id: 'profile2',
            //                 title: (
            //                     <>
            //                         <FormattedMessage id="profile" /> 02
            //                     </>
            //                 ),
            //                 type: 'item',
            //                 url: '/user/account-profile/profile2'
            //             },
            //             {
            //                 id: 'profile3',
            //                 title: (
            //                     <>
            //                         <FormattedMessage id="profile" /> 03
            //                     </>
            //                 ),
            //                 type: 'item',
            //                 url: '/user/account-profile/profile3'
            //             }
            //         ]
            //     },
            //     {
            //         id: 'user-card',
            //         title: <FormattedMessage id="cards" />,
            //         type: 'collapse',
            //         children: [
            //             {
            //                 id: 'card1',
            //                 title: (
            //                     <>
            //                         <FormattedMessage id="style" /> 01
            //                     </>
            //                 ),
            //                 type: 'item',
            //                 url: '/user/card/card1'
            //             },
            //             {
            //                 id: 'card2',
            //                 title: (
            //                     <>
            //                         <FormattedMessage id="style" /> 02
            //                     </>
            //                 ),
            //                 type: 'item',
            //                 url: '/user/card/card2'
            //             },
            //             {
            //                 id: 'card3',
            //                 title: (
            //                     <>
            //                         <FormattedMessage id="style" /> 03
            //                     </>
            //                 ),
            //                 type: 'item',
            //                 url: '/user/card/card3'
            //             }
            //         ]
            //     },
            //     {
            //         id: 'user-list',
            //         title: <FormattedMessage id="list" />,
            //         type: 'collapse',
            //         children: [
            //             {
            //                 id: 'list1',
            //                 title: (
            //                     <>
            //                         <FormattedMessage id="style" /> 01
            //                     </>
            //                 ),
            //                 type: 'item',
            //                 url: '/user/list/list1'
            //             },
            //             {
            //                 id: 'list2',
            //                 title: (
            //                     <>
            //                         <FormattedMessage id="style" /> 02
            //                     </>
            //                 ),
            //                 type: 'item',
            //                 url: '/user/list/list2'
            //             }
            //         ]
            //     }
            // ]
        },
        // {
        //     id: 'customer',
        //     title: <FormattedMessage id="customer" />,
        //     type: 'collapse',
        //     icon: icons.IconBasket,
        //     children: [
        //         {
        //             id: 'customer-list',
        //             title: <FormattedMessage id="customer-list" />,
        //             type: 'item',
        //             url: '/customer/customer-list',
        //             breadcrumbs: false
        //         },
        //         {
        //             id: 'order-list',
        //             title: <FormattedMessage id="order-list" />,
        //             type: 'item',
        //             url: '/customer/order-list',
        //             breadcrumbs: false
        //         },
        //         {
        //             id: 'order-details',
        //             title: <FormattedMessage id="order-details" />,
        //             type: 'item',
        //             url: '/customer/order-details'
        //         },
        //         {
        //             id: 'product',
        //             title: <FormattedMessage id="product" />,
        //             type: 'item',
        //             url: '/customer/product',
        //             breadcrumbs: false
        //         },
        //         {
        //             id: 'product-review',
        //             title: <FormattedMessage id="product-review" />,
        //             type: 'item',
        //             url: '/customer/product-review',
        //             breadcrumbs: false
        //         }
        //     ]
        // },
        {
            id: 'calendar',
            title: <FormattedMessage id="calendar" />,
            type: 'item',
            url: '/app/calendar',
            icon: icons.IconCalendar,
            breadcrumbs: false
        },
        {
            id: 'booking',
            title: <FormattedMessage id="booking" />,
            type: 'collapse',
            icon: icons.IconBrandBooking,
            children: [
                {
                    id: 'trial-list',
                    title: <FormattedMessage id="trial-list" />,
                    type: 'item',
                    url: '/booking/trial-list',
                    breadcrumbs: false
                },
                {
                    id: 'subscription-list',
                    title: <FormattedMessage id="subscription-list" />,
                    type: 'item',
                    url: '/booking/subscription-list',
                    breadcrumbs: false
                },
                {
                    id: 'group-list',
                    title: <FormattedMessage id="group-list" />,
                    type: 'item',
                    url: '/booking/group-list',
                    breadcrumbs: false
                },
            ]
        },
        {
            id: 'class',
            title: <FormattedMessage id="class" />,
            type: 'collapse',
            icon: icons.IconDeviceAnalytics,
            children: [
                {
                    id: 'class-create',
                    title: <FormattedMessage id="class-create" />,
                    type: 'item',
                    url: '/class/create',
                    breadcrumbs: false
                },
                {
                    id: 'class-list',
                    title: <FormattedMessage id="class-list" />,
                    type: 'item',
                    url: '/class/list',
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'chat',
            title: <FormattedMessage id="chat" />,
            type: 'item',
            icon: icons.IconMessages,
            url: '/app/chat'
        },
        // {
        //     id: 'kanban',
        //     title: 'Kanban',
        //     type: 'item',
        //     icon: icons.IconLayoutKanban,
        //     url: '/app/kanban'
        // },
        // {
        //     id: 'mail',
        //     title: <FormattedMessage id="mail" />,
        //     type: 'item',
        //     icon: icons.IconMail,
        //     url: '/app/mail'
        // },
        // {
        //     id: 'contact',
        //     title: <FormattedMessage id="contact" />,
        //     type: 'collapse',
        //     icon: icons.IconNfc,
        //     children: [
        //         {
        //             id: 'c-card',
        //             title: <FormattedMessage id="cards" />,
        //             type: 'item',
        //             url: '/app/contact/c-card',
        //             breadcrumbs: false
        //         },
        //         {
        //             id: 'c-list',
        //             title: <FormattedMessage id="list" />,
        //             type: 'item',
        //             url: '/app/contact/c-list',
        //             breadcrumbs: false
        //         }
        //     ]
        // },
        // {
        //     id: 'e-commerce',
        //     title: <FormattedMessage id="e-commerce" />,
        //     type: 'collapse',
        //     icon: icons.IconBasket,
        //     children: [
        //         {
        //             id: 'products',
        //             title: <FormattedMessage id="products" />,
        //             type: 'item',
        //             url: '/e-commerce/products'
        //         },
        //         {
        //             id: 'product-details',
        //             title: <FormattedMessage id="product-details" />,
        //             type: 'item',
        //             url: '/e-commerce/product-details/default',
        //             breadcrumbs: false
        //         },
        //         {
        //             id: 'product-list',
        //             title: <FormattedMessage id="product-list" />,
        //             type: 'item',
        //             url: '/e-commerce/product-list',
        //             breadcrumbs: false
        //         },
        //         {
        //             id: 'checkout',
        //             title: <FormattedMessage id="checkout" />,
        //             type: 'item',
        //             url: '/e-commerce/checkout'
        //         }
        //     ]
        // }
    ]
};

export default application;
