import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer } from 'react';
import config from '../config';

// third-party
import { Chance } from 'chance';
import jwtDecode from 'jwt-decode';

// reducer - state management
import { LOGIN, LOGOUT } from 'store/actions';
import accountReducer from 'store/accountReducer';

// project imports
import Loader from 'ui-component/Loader';
// import axios from 'utils/axios';
import authHeader from '../Services/auth-header'; 
import axios from 'axios';

const chance = new Chance();

// constant
const initialState = {
    isLoggedIn: false,
    isInitialized: false,
    user: null
};

const verifyToken = (serviceToken) => {
    if (!serviceToken) {
        return false;
    }
    const decoded = jwtDecode(serviceToken);
    /**
     * Property 'exp' does not exist on type '<T = unknown>(token: string, options?: JwtDecodeOptions | undefined) => T'.
     */
    return decoded.exp > Date.now() / 1000;
};

const setSession = (serviceToken) => {
    if (serviceToken) {
        localStorage.setItem('serviceToken', serviceToken);
        axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    } else {
        localStorage.removeItem('serviceToken');
        delete axios.defaults.headers.common.Authorization;
    }
};

// ==============================|| JWT CONTEXT & PROVIDER ||============================== //
const JWTContext = createContext(null);

export const JWTProvider = ({ children }) => {
    const [state, dispatch] = useReducer(accountReducer, initialState);

    useEffect(() => {
        const init = async () => {
            try {
                const serviceToken = window.localStorage.getItem('serviceToken');
                if (serviceToken && verifyToken(serviceToken)) {
                    setSession(serviceToken);
                    const response = await axios.get(`${config.API_SERVER}/api/tutor/me`, { headers: authHeader() });
                    const user = response.data.tutor;
                    dispatch({
                        type: LOGIN,
                        payload: {
                            isLoggedIn: true,
                            user: {
                                organizationId: user.organization_id,
                                firstName: user.credential.firstName,
                                lastName: user.credential.lastName,
                                email: user.credential.email,
                                telephone: user.credential.telephone,
                                birthday: user.credential.birthday,
                                avatar: user.avatar !== null? user.avatar.url : null,
                                courseId: user.course.id,
                                course: user.course.name,
                                hourRate:user.hour_rate,
                                // language: user.language,
                                street:user.address !== null? user.address.street : null,
                                city:user.address !== null? user.address.city : null,
                                state:user.address !== null? user.address.state : null,
                                country:user.address !== null? user.address.country :null,
                                postcode:user.address !== null? user.address.postcode : null,
                                bankHolderName: user.bank? user.bank.holder_name : "",
                                bankHolderType: user.bank? user.bank.holder_type: "",
                                bankRoutingNumber: user.bank? user.bank.routing_number: "",
                                bankLast4: user.bank? user.bank.last4: "",
                                bankCurrency: user.bank? user.bank.currency: ""
                            }
                        }
                    });
                } else {
                    dispatch({
                        type: LOGOUT
                    });
                }
            } catch (err) {
                console.error(err);
                dispatch({
                    type: LOGOUT
                });
            }
        };

        init();
    }, []);

    const login = async (email, password) => {
        const response = await axios.post(`${config.API_SERVER}/api/login/tutor`, { email, password });
        // const { serviceToken, user } = response.data;
        const serviceToke  = response.data.access_token;
        const user = response.data.tutor;
        setSession(serviceToke);
        dispatch({
            type: LOGIN,
            payload: {
                isLoggedIn: true,
                user: {
                    organizationId: user.organization_id,
                    firstName: user.credential.firstName,
                    lastName: user.credential.lastName,
                    email: user.credential.email,
                    telephone: user.credential.telephone,
                    birthday: user.credential.birthday,
                    avatar: user.avatar !== null? user.avatar.url : null,
                    courseId: user.course.id,
                    course: user.course.name,
                    hourRate:user.hour_rate,
                    // language: user.language,
                    street:user.address !== null? user.address.street : null,
                    city:user.address !== null? user.address.city : null,
                    state:user.address !== null? user.address.state : null,
                    country:user.address !== null? user.address.country :null,
                    postcode:user.address !== null? user.address.postcode : null,
                    bankHolderName: user.bank? user.bank.holder_name : "",
                    bankHolderType: user.bank? user.bank.holder_type: "",
                    bankRoutingNumber: user.bank? user.bank.routing_number: "",
                    bankLast4: user.bank? user.bank.last4: "",
                    bankCurrency: user.bank? user.bank.currency: ""
                }
            }
        });
    };

    const register = async (email, password, firstName, lastName) => {
        // todo: this flow need to be recode as it not verified
        const id = chance.bb_pin();
        const response = await axios.post('/api/account/register', {
            id,
            email,
            password,
            firstName,
            lastName
        });
        let users = response.data;

        if (window.localStorage.getItem('users') !== undefined && window.localStorage.getItem('users') !== null) {
            const localUsers = window.localStorage.getItem('users');
            users = [
                ...JSON.parse(localUsers),
                {
                    id,
                    email,
                    password,
                    name: `${firstName} ${lastName}`
                }
            ];
        }

        window.localStorage.setItem('users', JSON.stringify(users));
    };

    const logout = () => {
        setSession(null);
        dispatch({ type: LOGOUT });
    };

    const resetPassword = (email) => console.log(email);

    const updateProfile = () => {};

    if (state.isInitialized !== undefined && !state.isInitialized) {
        return <Loader />;
    }

    return (
        <JWTContext.Provider value={{ ...state, login, logout, register, resetPassword, updateProfile }}>{children}</JWTContext.Provider>
    );
};

JWTProvider.propTypes = {
    children: PropTypes.node.isRequired
};

export default JWTContext;
